<script setup>
import AppMenu from './AppMenu.vue';
</script>

<template>
    <div class="layout-sidebar">
        <app-menu></app-menu>
    </div>
</template>

<style lang="scss" scoped></style>
