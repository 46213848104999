import { createRouter, createWebHistory } from "vue-router";
import AppLayout from "@/layout/AppLayout.vue";
//lazy loading, separate into modules for minimze the bundles
const Login = () => import("@/views/Login.vue");
const Welcome = () => import("@/views/Welcome.vue");
const TruckUsageChart = () => import("@/views/transports/TruckUsageChart.vue");
const DriverUsageChart = () => import("@/views/transports/DriverUsageChart.vue");
const ValeoTermicoLentoMovimiento = () => import("@/views/valeo-termico/LentoMovimiento.vue");

const router = createRouter({
  history: createWebHistory(),
  routes: 	[
	  {
	    path: '/',
	    component: AppLayout,
	    children: [
	      {
	        path: '/',
					name: 'welcome',
	        component: Welcome,
	      },
				{
	        path: '/transportes/grafica-uso-unidad',
					name: 'transportes_grafica-uso-unidad',
	        component: TruckUsageChart,
	      },
				{
				  path: '/transportes/grafica-uso-chofer',
					name: 'transportes_grafica-uso-chofer',
				  component: DriverUsageChart,
				},
				{
	        path: '/valeo-termico/lento-movimiento',
					name: 'valeo-termico_lento-movimiento',
	        component: ValeoTermicoLentoMovimiento,
	      }
	    ]
	  },
		{
			path: '/login',
			name: 'login',
			component: Login,
		}
	]
});

export default router;
