
const csrfToken = () => {
	return window.csrfToken();
};

const axios = () => {
	return window.axios;
};

const extractError = (error) => {
	if (error === null) {
		return 'Error al obtener la respuesta';
	}
	
	let msg = '';
	if(error.response != undefined && error.response != null && error.response.data != undefined && error.response.data != null) {
		msg = error.response.data;
		if (msg.message !== undefined && msg.message !== null) {
			msg = msg.message;
		} else if (msg.error !== undefined && msg.error !== null) {
			msg = msg.error;
		} else {
			msg = msg.toLowerCase().startsWith('<!DOCTYPE'.toLowerCase()) ? 'Ocurrió un error' : msg;
		}
	} else if(error instanceof Error) {
		msg = error.message;
	} else {
		msg = error;
	}
	return msg;
};

const echartsCallbacks = (options) => {
	//algunas opciones de echarts pueden ser callbacks, pero el server solo nos envia strings asi que las convertimos a funciones
	for(let prop in options){
		if (!options.hasOwnProperty(prop)) { continue; }
		
		if(Array.isArray(options[prop])){ 
			for(let sub in options[prop]) {
				echartsCallbacks(sub);
			}
		} else if(typeof options[prop] === 'object') {
			echartsCallbacks(options[prop]);
		}	else if(typeof options[prop] === 'string' && options[prop].startsWith('toFunc: ')) {
			options[prop] = new Function('return ' + options[prop].substring(8))();
		}
	};
	return options;
};

export {
	csrfToken,
	axios,
	extractError,
	echartsCallbacks
};