<script setup>
import { ref } from 'vue';

import AppMenuItem from './AppMenuItem.vue';

//https://lucide.dev/icons
//los iconos que aqui se indiquen se deben importar en AppMenuItem.vue para tree shaking
const model = ref([
    {
        label: 'CAZVI',
				visible: true,
        items: [
					{ 
						label: 'Inicio', 
						icon: 'house', 
						to: '/' 
					}
				]
    },
    {
        label: 'Transportes',
        items: [
            {
                label: 'Utilización de Unidades',
                icon: 'truck',
                to: '/transportes/grafica-uso-unidad'
            },
						{
                label: 'Utilización de Choferes',
                icon: 'person-standing',
                to: '/transportes/grafica-uso-chofer'
            }
        ]
    },
    {
        label: 'Valeo Térmico',
        items: [
            {
                label: 'Lento Movimiento',
                icon: 'circle-arrow-right',
                to: '/valeo-termico/lento-movimiento'
            }
        ]
    }
]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>
