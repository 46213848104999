/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Spring Boot back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import axios from "axios";
import { AxiosInstance } from "axios";

import { createApp } from "vue";
import App from "./App.vue";
import router from './router';

import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import { definePreset } from '@primevue/themes';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from "primevue/toastservice";
import StyleClass from 'primevue/styleclass'; 

import "../css/app.css";

const token = document.head.querySelector('meta[name="_csrf"]');
//const vueapp = document.body.querySelector("#vueapp");

window.csrfToken = function () {
	return token != null ? token.content : 'blablabla';
}

window.axios = axios.create();
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = window.csrfToken();

//cazvi default preset
//colors copied from js/layout/AppConfigurator.vue
let CazviPreset = definePreset(Aura, {
  semantic: {
    primary: {
			50: '#eff6ff', 100: '#dbeafe', 200: '#bfdbfe', 300: '#93c5fd', 400: '#60a5fa', 500: '#3b82f6', 600: '#2563eb', 700: '#1d4ed8', 800: '#1e40af', 900: '#1e3a8a', 950: '#172554'//blue
      //50: '#fff1f2', 100: '#ffe4e6', 200: '#fecdd3', 300: '#fda4af', 400: '#fb7185', 500: '#f43f5e', 600: '#e11d48', 700: '#be123c', 800: '#9f1239', 900: '#881337', 950: '#4c0519'//rose
    }
  }
});

const app = createApp(App);//, { ...vueapp.dataset });
app.directive('styleclass', StyleClass);
app.use(router);
app.use(PrimeVue, {
	theme: {
		preset: CazviPreset,
		options: {
			darkModeSelector: '.app-dark' 
		}
	}
});
app.use(ToastService);
app.use(ConfirmationService);
app.mount('#vueapp');



